import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Template({ data, location }) {
  const { mdx } = data
  const { frontmatter, body } = mdx
  let glossaryPath

  try {
    glossaryPath = location.pathname.match(/^\/([^?/]+)/)[1]
  } catch (e) {}

  return (
    <Layout title={frontmatter.term}>
      <SEO title={`${frontmatter.term} | Glossar`} />
      <div className="">
        <MDXRenderer>{body}</MDXRenderer>

        {glossaryPath && (
          <p className="border-top mt-3 pt-2">
            <Link to={`/${glossaryPath}/`} state={location.state}>
              « Zurück zur Glossar-Übersicht
            </Link>
          </p>
        )}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: {}, fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        term
        short
        path
        desc_short
        alternative
      }
    }
  }
`
